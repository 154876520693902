const NavBar = () => {
  return (
    <nav className="bg-white border-b w-full py-1">
      <div className="items-center px-4 max-w-screen-xl mx-auto flex">
        <div className="flex items-center justify-between py-2">
          <a href="/">
            <div className="font-sans text-gray-700 text-xl">SpreadAlgo.com</div>
          </a>
        </div>
        <div className="flex items-center justify-between py-2 px-4">
          <a href="https://tradealgos.net" target="_blank" rel="noreferrer">
            <div className="font-sans text-gray-700 text-xl">TradeAlgos.net</div>
          </a>
        </div>
        <div className={`flex-1 pb-0 mt-0 block`}>
          <ul className="justify-end items-center flex space-x-6 space-y-0">
            <div className="items-center gap-x-6 flex space-y-0">
              <li>
                <div className="flex items-center justify-between py-2 px-4">
                  <a href="https://vanderwalt.de" target="_blank" rel="noreferrer">
                    <div className="font-sans text-gray-700 text-xl">vanderwalt.de</div>
                  </a>
                </div>
              </li>
              <li>
                <a
                  href="https://t.me/vanrohan"
                  target="_blank"
                  rel="noreferrer"
                  className="py-3 px-4 font-medium text-center text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-700 active:shadow-none rounded-lg shadow inline"
                >
                  Questions
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
