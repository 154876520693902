import NavBar from "../components/NavBar";
import { useRouteLoaderData } from "react-router";
import ChartPage from "./ChartPage";
import { useParams } from "react-router-dom";
import { Spinner } from "@blueprintjs/core";
import { Await } from "react-router-dom";
import { useEffect, Suspense } from "react";

function RootLayout() {
  const data = useRouteLoaderData("root");

  return (
    <div className="flex flex-col content-center items-center justify-center">
      <NavBar />
      <Suspense
        fallback={
          <div style={{ height: "50vh", alignContent: "center" }}>
            <Spinner aria-label="Loading..." intent="primary" size={73} />
          </div>
        }
      >
        <Await
          resolve={data.allParameters}
          errorElement={<p>Error loading parameters!</p>}
        >
          {(resolvedParams) => <ChartPage params={resolvedParams}/>}
        </Await>
      </Suspense>
    </div>
  );
}

export default RootLayout;
