/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineController, LineElement, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, PointElement, LineController, LineElement, zoomPlugin, ...registerables);

type SpreadMatrixProps = {
  spread_data: Point[];
  leg_1_data: Point[];
};

export default function SpreadMatrix(props: SpreadMatrixProps) {
  const chartConfig = {
    type: "line",
    data: {
      datasets: [
        {
          label: "spread",
          data: props.spread_data.map((p) => ({ x: p.x, y: p.y })),
          showLine: true,
          borderColor: "#BC3D41",
          backgroundColor: "#BC3D41",
          pointStyle: false,
          borderWidth: 1,
          yAxisID: "yAxisLeft",
        },
        {
          label: "leg_1_mid",
          data: props.leg_1_data.map((p) => ({ x: p.x, y: p.y })),
          showLine: true,
          borderColor: "#111111",
          backgroundColor: "#111111",
          pointStyle: false,
          borderWidth: 1,
          borderDash: [5, 5],
          
          yAxisID: "yAxisRight",
        },
      ],
    },
    options: {
      scales: {
        x: {
          type: "time",
          time: {
            // unit: "hour",
            displayFormats: {
              minute: "YYYY-MM-DD HH:mm",
            },
          },
          ticks: { source: "auto" },
          grid: {
            //display: false,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: true,
          },
          // add a label to the axis
          title: {
            display: true,
            text: "Date & Time",
            color: "#222222",
          },
          font: {
            size: 8,
          },
        },
        yAxisLeft: {
          position: "left",
          title: {
            display: true,
            text: "Intermarket Spread (leg 1 vs leg 2)",
            color: "#222222",
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
        yAxisRight: {
          position: "right",
          title: {
            display: true,
            text: "Leg 1 Mid",
            color: "#222222",
          },
          // min: 0,
          // max: 10000,
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
      },
    },
  };

  return <Chart style={{ height: "75vh" }} type="line" data={chartConfig.data} options={chartConfig.options} />;
}
